
import "css/base/variables.css";

import 'css/quasar/@index.sass';
import 'css/vendor/materialSymbols.sass'

import "css/base/body.css";
import "css/base/layout.css";

import "css/custom.css";

import "css/base/tailwind.css";

document.addEventListener('DOMContentLoaded', () =>
  document.body.classList.add('DOMContentLoaded')
)
